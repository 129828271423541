
import { RevContentBlock, openModal } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'

import { tw } from '@core/tailwind'
import { trackClick } from '@tracking/events'

import { imageValidator } from '../../helpers/image'
import VideoModal from '../private/VideoModal'

// Displays a description text and a picture, and optionally a call to action
export default {
  components: {
    RevContentBlock,
    VideoModal,
  },
  props: {
    ctaLink: {
      type: Object,
      default: () => ({}),
    },
    ctaVideo: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      validator: imageValidator,
      default: () => ({}),
    },
    text: {
      type: String,
      default: '',
    },
    textPosition: {
      type: String,
      required: true,
    },
    contentTitle: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    enableDarkMod: {
      type: Boolean,
      default: false,
    },
    tracking: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dynamicClasses() {
      const darkTheme = this.enableDarkMod ? 'theme-dark' : ''
      const mobilePadding = this.backgroundColor ? tw`p-6` : ''
      const desktopPadding = this.isContentRight
        ? tw`md:p-0 md:pr-6`
        : tw`md:p-0 md:pl-6`

      return `${this.backgroundColor} ${darkTheme} ${mobilePadding} ${desktopPadding}`
    },
    hasVideo() {
      return !isEmpty(this.ctaVideo)
    },
    hasLink() {
      return !isEmpty(this.ctaLink)
    },
    hasRoundedImage() {
      return !this.backgroundColor
    },
    buttonLabel() {
      return this.hasLink ? this.ctaLink.label : this.ctaVideo.label
    },
    buttonVariant() {
      return this.enableDarkMod ? 'primary' : 'secondary'
    },
    modalName() {
      return `${this.id}-video-modal`
    },
    isContentRight() {
      return this.textPosition === 'right'
    },
    // @todo: remove when the DS modal changes the fact that
    // the close button is displayed only when there is a title
    emptyVideoTitle() {
      return ' '
    },
    linkTarget() {
      if (!this.ctaLink || !this.ctaLink.link) {
        return '_self'
      }

      if (this.ctaLink.link.targetBlank) {
        return '_blank'
      }

      return '_self'
    },
  },
  methods: {
    sendTrackingData() {
      trackClick({
        ...this.tracking,
        name: this.contentTitle,
      })
      if (this.hasVideo) {
        openModal(this.modalName)
      }
    },
    openVideoModal() {
      openModal(this.modalName)
      this.sendTrackingData()
    },
  },
}
