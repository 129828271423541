
import { RevModal } from '@backmarket/design-system'

export default {
  components: {
    RevModal,
  },
  props: {
    videoId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
